<template>
  <div class="myDiv">
    <div id="paypal-container">
      <a href="https://www.paypal.com/in/webapps/mpp/paypal-popup" title="How PayPal Works" onclick="javascript:window.open('https://www.paypal.com/in/webapps/mpp/paypal-popup','WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'); return false;"><img src="https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-100px.png" border="0" alt="PayPal Logo"></a>
      </div>
    </div>
</template>

<script>
import { loadScript } from "@paypal/paypal-js";
import { subscriptionApi, getPlanList, orderPatch } from "@/api";
let paypal;
export default {
  components: {},
  props: {},
  data() {
    return {
      clientId: {
        sandbox: "",
      },
      payid: "",
      payuuid: "",
    };
  },
  watch: {},
  computed: {},
  methods: {
    async pay_with_paypal() {
      try {
        paypal = await loadScript({
          clientId:
            "AbzJPmLGDWxiPPGVfLJGDN5oSKHEl9KG1_t_R83Wkf_3h_lXbhMPBuEv4EfAM9_8lnZYjfBx6D6OloOF",
          currency: "USD",
          vault:true ,//是否存储支付方式
        });
      } catch (error) {
        console.error("11111 failed to load the PayPal JS SDK script", error);
      }

      const that = this;
      console.log("payuuid-1", this.payuuid);
      if (paypal) {
        try {
          await paypal
            .Buttons({
              async createOrder(data, actions) {
                
              },
              async onApprove(data) {
              },
              onError() {},
              style: {
                layout: "horizontal",
                color: "white",
                shape: "pill",
                label: "paypal",
                height: 35,
                // fundingSource: "paypal",
                tagline: false,
              },
            })
            .render("#paypal-container");
        } catch (error) {
          console.error("2222 failed to render the PayPal Buttons", error);
        }
      }
    },
  },
  created() {},
  async mounted() {
    getPlanList({ page: 1, size: 10 }).then((res) => {
      const { id, uuid } = res.data[0];
      this.payid = id;
      this.payuuid = uuid;
      console.log("-----", id, uuid);
      // this.pay_with_paypal();
    });
    // await this.pay_with_paypal();
  },
};
</script>
<style lang="less" scoped>
.myDiv {
}
#paypal-container {
    width: 3.50667rem;
    height: 0.862rem;
    border-radius: 5.33333rem;
    background: white;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items:center;
}
#paypal-container a , #paypal-container img {
    width: 1.5rem;
    height: 0.6rem;
    vertical-align: -0.15em;
    overflow: hidden;
    display: inline-block;
}
</style>
