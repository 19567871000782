<template>
  <div class="coins-box">
    <div class="coin-con">
      <div class="tip-paypal">
        <!-- <img loading="lazy" src="@/assets/images/img-paypal.png" alt="" /> -->
        <payCom ref="payCom"></payCom>
      </div>
      <div class="the-list">
        <div
          class="one-coin fx-vb"

          v-for="(item, index) in priList"
          :key="item.id"
          :class="{cur: index === 0}"
          :data-id="item.id"
          @click="payClick(item)"
          :id='"item-"+item.items[0].id'
          data-plan-type="MERCHANT_INITIATED_BILLING"
        >
          <div class="most-tip fx-vc" v-if="index === 0">Most Poupular</div>
          <div class="info fx-vc">
            <span>Weekly</span>
            <span
              >$<strong>{{ item.items[0].price/100 }}</strong></span
            >
            <del>${{ item.items[0].origin_price/100 || 19.9 }}</del>
          </div>
          <div class="btn fx-vc">Subscribe</div>
          <div class="icon-hand">
            <img loading="lazy" src="@/assets/images/icon-hand.png" alt="" />
          </div>
        </div>
        <!-- todo: comment ready  to apply -->
        <!-- <div class="one-coin fx-vb cur">
          <div class="info fx-vc">
            <span>Weekly</span>
            <span>$<strong>1.99</strong></span>
            <del>$19.00</del>
          </div>
          <div class="btn fx-vc">Subscribe</div>
          <div class="icon-hand">
            <img loading="lazy" src="@/assets/images/icon-hand.png" alt="" />
          </div>
        </div>
        <div class="one-coin fx-vb">
          <div class="info fx-vc">
            <span>Weekly</span>
            <span>$<strong>1.99</strong></span>
            <del>$19.00</del>
          </div>
          <div class="btn fx-vc">Subscribe</div>
          <div class="icon-hand">
            <img loading="lazy" src="@/assets/images/icon-hand.png" alt="" />
          </div>
        </div> -->
      </div>
    </div>

    <div class="coin-tip">
      <img loading="lazy" src="@/assets/images/coin-tip.png" alt="" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import payCom from "../pay/payCom.vue";
import { rechargeListApi, orderSubmitApi,GetReportParam } from "@/api";
import Cookies from "js-cookie";
import { genRandomString  } from "@/util/index";

export default {
  name: "CoinsBox",
  data() {
    return {
      priList: [],
    };
  },
  components: { payCom },
  computed: {
    ...mapState({}),
  },
  methods: {
    async rechargeList() {
      const $this = this;
      const res = await rechargeListApi()
      // .then((res) => {
      //   this.priList = res.data.vip_list;
      //   return true;
      // })
      this.priList = res.data.vip_list; 
      // .then(() => { }); });
        
     
    },
    bindPayPalClick(){
      const $this = this;
      const paypal = window.paypal;
      console.log("paypal:",paypal);
      this.priList.forEach((items) => {
        const item = items.items;
        const { id } = item[0];
        const id_str = `item-${id}`;
        const dom = document.getElementById(id_str);
        console.log("dom:",dom);
        if(dom){
         dom.addEventListener('click', () => {
          const ele = document.querySelector("#paypal-container")
          console.log("click item:",ele);
          ele.click();
          console.log("on click item:");
          paypal.Buttons({
            createOrder:function(data,actions){
                console.log("create order data:", data )
                 return actions.order.create({
                  purchase_units:[{
                    amount:{
                      value:item[0].price,
                      currency_code:"USD"
                    }
                  }],
                  application_context: {
                     shipping_preference: 'NO_SHIPPING' // 如果不需要收货地址
                  }
                 }); 
              },
              onApprove:function(data,actions){ 
                return actions.order.capture().then(function(details){
                  console.log("onApprove details:",details);
                  return orderSubmitApi({
                    app_id: "novellettes",
                    user: $this.$store.state.userData.uuid,
                    tid: $this.$store.state.userData.uuid,
                    sid: $this.$store.state.userData.uuid,
                    cid: "123",
                    lan: window.navigator.language,
                    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    pay_type: 1,
                    cmid: $this.$store.state.userData.uuid,
                    token: details.purchase_units[0].payments.captures[0].id,
                    return_url: location.href,
                    cmid: details.payer.payer_id,
                    source: "1",})
                });
              },
              onError:function(err){
                console.log("paypal error:",err);
              }
            }).render("#paypal-container");

         });
        }
      });
    },
    payClick(item) {
      console.log("window paypal:",window.paypal); 
      console.log(1, item);
      const $this = this;
      const { items } = item;
      const { price, token } = items[0];
      const abuid = Cookies.get("abuid");
      const user = abuid //this.$store.state.userData.name || abuid;
      const userToken = Cookies.get('atoken') || "";
      const reportData = GetReportParam();
      orderSubmitApi({
        app_id: "novellettes",
        user: user,
        tid: userToken ,//reportData.tid,
        sid: userToken,
        cid: ""+reportData.cid,
        lan: window.navigator.language,
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        pay_type: 1,
        cmid: genRandomString(24),
        token: token,
        return_url: location.href,
        source: "1",
      }).then(res=>{
        console.log("orderSubmitApi:",res);
        if(res.code === 0){
          // window.open(res.data.command);
          location.href = res.data.command;
        }
      }).catch(e=>{
        console.log("orderSubmitApi error:",e);
      });
    },
  },
  mounted() {
    this.rechargeList();
  },
};
</script>

<style lang="less">
@import url("./index.less");
</style>
